<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
//import Multiselect from "vue-multiselect";
//import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
import { format } from "date-fns";
import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    //Multiselect,
    //ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "GESTION DES LIGNES ",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "GESTION DES LIGNES",
          active: true,
        },
      ],
      tyMedia: [
        {
          name: "Texte",
          value: "text",
        },
        {
          name: "Image",
          value: "image",
        },
        {
          name: "Vidéo",
          value: "video",
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },
      ],

      userTypeList: [
        {
          id: "CONDUCTEUR",
          value: "MOTO",
        },
        {
          id: "PARTICULIER",
          value: "VOITURE",
        },
        {
          id: "TAXIMAN",
          value: "TAXI",
        },
        {
          id: "SOCIETE",

          value: "BUS",
        },
        { id: "PASSAGER", value: "PASSAGER" },
      ],
      url_link: "",
      userTypeSelect: "",
      messageSelect: null,
      mediaSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
          userId: "",
        },
      ],
      value: null,
      value1: null,
      options: ["Sexe", "Taille", "Vehicule"],
      idUser: 0,
      requestData: [],
      requestDataD: [],
      rowsrequestData: 0,
      rowsrequestDataD: 0,
      nbreUser: "",
      totalRows: 1,
      changeCurrent: 1,
      changePerPage: 100,
      currentPage: 1,
      currentPageD: 1,
      perPage: 100,
      perPageD: 100,
      pageOptions: [100, 250, 500],
      pageOptionsD: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      paginatedDate: [],
      fields: [
        { key: "compagnie", sortable: true, label: "COMPAGNIE" },
        { key: "phone", sortable: true, label: "Téléphone" },
        { key: "email", sortable: true, label: "Email" },
        { key: "startPoint", sortable: true, label: "Départ" },
        { key: "endPoint", sortable: true, label: "Arrivé" },
        { key: "escale", sortable: true, label: "Escale" },
        { key: "pays", sortable: true, label: "Pays" },
        //{ key: "type", sortable: true, label: "Type" },
        { key: "addDate", sortable: true, label: "Date D'ajout" },
        { key: "statut", sortable: true, label: "Statut" },

        // { key: "endDa", sortable: true, label: "Date de début" },
        // { key: "dateIns", sortable: true, label: "Inscription" },
        //{ key: "manuel", sortable: true, label: "Manuel" },
        // { key: "action", label: "Actions" },
      ],
      showDisable: false,
      startValue: 1,
      endValue: 500,
      totalValue: "0",
      showDelete: false,
      showEdit: false,
      showSucess: false,
      showEchec: false,
      showSucessEdit: false,
      showEchecEdit: false,
      userToUse: 0,
      line: {
        id: "",
        company: "",
        startPoint: "",
        endPoint: "",
        startHour: "",
        endHour: "",
        placeAvailable: "",
        escale: '',
        country: "",
      },

      infoUser: {
        id: "",
        firstname: "",
        lastname: "",
        profession: "",
        birthday: "",
        adresse: "",
        ville: "",
        pays: "",
        userType: "",
        tel: "",
        email: "",
        personneUrgence: "",
        contactUrgence: "",
        name: "",
        boitePostal: "",
        apropos: "",
        permisCategorie: "",
        permisAnnee: "",
        permisNumuero: "",
        indicatifTel: "",
        indicatifContactUrg: "",
      },
      userTypeLocal: null,
      showMessage: false,
      showMessageMail: false,
      editor: ClassicEditor,
      editorData: "",
      deleteModal: false,
      userChargeLoader: false,
      newDataUser: [],
      newStart: false,
      recherche: "",
      activeConducteur: false,
      onlyConducteur: [],
      canceledRequestData: null,
      rowsCanceledRequestData: null,
      totalCanceledRows: 0,
      showLineModal: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.requestData.length;
    },
    rowsinactif() {
      return this.requestDataD.length;
    },
  },
  async mounted() {
    this.init();
    this.inactif();
    this.userTotal();
    /*  const dots = document.getElementById('#loaading__dots')
  const addAnimate = ()=>{
    dots.classList.add('animate')
    setTimeout(()=>{
      dots.classList.remove('aniamte');
      setTimeout(()=>{
        addAnimate()
      },100)
    }, 2600)


    }
    addAnimate(); */
  },
  methods: {
    async init() {
      //Chargement des données
      const data = await apiRequest("GET", "courses-attentes", undefined, false);
      console.log("courses en attentes", data.data)
      if (data && data.data) {
        const sortedData = data.data.sort((a, b) => b.id - a.id);
        const formattedTable = sortedData.map((ride) => {


          return {
            id: ride.passager.id,
            passenger: ride.passager.personne.lastname + " " + ride.passager.personne.firstname,
            passengerPhone: ride.passager.personne.tel && ride.passager.personne.indicatifTel != "" ? ride.passager.personne.indicatifTel + ride.passager.personne.tel : "",
            pays: ride.pays,
            requestDate: new Date(ride.dateCreation).toLocaleDateString("Fr-fr") + ' ' + new Date(ride.dateCreation).toLocaleTimeString("Fr-fr", "HH:mm"),
            email: ride.passager.personne.email,
            statut: ride.statut || "Indisponible",
          };
        });
        this.requestData = formattedTable;
        this.rowsrequestData = formattedTable.length;
        this.totalRows = formattedTable.length;
      }
    },

    //  GET /courses-annulee

    async canceledRequest() {
      //Chargement des données
      const data = await apiRequest("GET", "courses-annulee", false);
      console.log("courses en attentes", data.data)
      if (data && data.data) {
        const sortedData = data.data.sort((a, b) => b.id - a.id);
        const formattedTable = sortedData.map((ride) => {


          return {
            id: ride.passager.personne.id,
            passenger: ride.passager.personne.lastname + " " + ride.passager.personne.firstname,
            passengerPhone: ride.passager.personne.tel && ride.passager.personne.indicatifTel != "" ? ride.passager.personne.indicatifTel + ride.passager.personne.tel : "",
            pays: ride.pays,
            requestDate: new Date(ride.dateCreation).toLocaleDateString("Fr-fr") + ' ' + new Date(ride.dateCreation).toLocaleTimeString("Fr-fr", "HH:mm"),
            email: ride.passager.personne.email,
            statut: ride.statut || "Indisponible",
          };
        });
        this.canceledRequestData = formattedTable;
        this.rowsCanceledRequestData = formattedTable.length;
        this.totalCanceledRows = formattedTable.length;
      }
    },

    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },
    async editClick(row) {
      this.showEdit = true;
      this.infoUser.id = row.id;
      const dataDetail = await apiRequest(
        "POST",
        "admin/detail-user",
        { id: row.id },
        false
      );

      if (dataDetail && dataDetail.data) {
        this.infoUser.lastname = dataDetail.data.personne.lastname;
        this.infoUser.firstname = dataDetail.data.personne.firstname;
        this.infoUser.profession = dataDetail.data.personne.profession;
        (this.infoUser.birthday = format(
          new Date(dataDetail.data.personne.birthday),
          "dd/MM/yyyy"
        )),
          (this.infoUser.adresse = dataDetail.data.personne.adresse);
        this.infoUser.ville = dataDetail.data.personne.ville;
        this.infoUser.pays = dataDetail.data.personne.pays;
        this.infoUser.email = dataDetail.data.personne.email;
        this.infoUser.contactUrgence = dataDetail.data.personne.contactUrgence;
        this.infoUser.personneUrgence =
          dataDetail.data.personne.personneUrgence;
        this.infoUser.telephone = dataDetail.data.personne.tel;
        this.infoUser.userTypeSelect = dataDetail.data.userType;
        this.userTypeLocal = dataDetail.data.userType;
        this.infoUser.indicatifTel = dataDetail.data.personne.indicatifTel;
        this.infoUser.indicatifContactUrg =
          dataDetail.data.personne.indicatifContactTel;
      }
    },
    desactiverUser(row) {
      this.userToUse = row.id;

      this.showDelete = true;
    },
    async desactiveUser() {
      this.showDelete = false;
      const data = await apiRequest(
        "POST",
        "admin/suspendre-user",
        { idUser: this.userToUse },
        false
      );

      if (data && data.data) {


        this.$toast.success("Utilisateur désactivé avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });

        this.init();
      }
    },

    messageShow(row, typeMessage) {
      this.messageSelect = typeMessage;

      if (typeMessage.value == 0) this.showMessage = true;
      if (typeMessage.value == 1) this.showMessageMail = true;
      this.messageContent.userId = row.id;
    },
    async activeUser() {
      const data = await apiRequest(
        "POST",
        "admin/activer-user",
        { idUser: this.userToUse },
        false
      );

      if (data && data.data) {

        this.$toast.success("Utilisateur activé avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.deleteModal = false;
        this.init();
      }
    },
    deleteUser(row) {
      this.userToUse = row.id;
      this.showDelete = true;
    },
    setDepartContry: function (place) {
      //console.log('place:', place)
      if (place) {
        //console.log("place:", place);
        /*console.log('place.formatted_address:', place.formatted_address)*/
        this.infoUser.ville = place.formatted_address;
      }
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.badge === true) return "table-warning";
    },
    activeUserPop(row) {
      this.userToUse = row.id;
      this.deleteModal = true;
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      router.push(`/user/user_info/${row.id}`);
    },
    openNewTab(row) {
      window.open(`https://adminrmobility.raynis.co/user/user_info/${row.id}`, '_blank');
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
      this.activeConducteur = false;
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
      this.activeConducteur = false;
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
      this.activeConducteur = false;
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
      this.activeConducteur = false;
    },

    setLineStartPoint: function (place) {

      if (place) {
        this.line.startPoint = place.formatted_address;
        this.line.endPoint = place.geometry.location.lng();
        this.eventForm.latitude = place.geometry.location.lat();
      }
    },
  },

  watch: {
    activeConducteur(newValue, oldValue) {
      if (newValue === true) {
        this.userChargeLoader = true
        console.log("Old", oldValue);


        if (this.newDataUser.length != 0) {
          this.userChargeLoader = true
          this.requestData = [];
          this.newDataUser.forEach((element) => {
            if (element.trajet >= 1) {
              this.requestData.push(element);
            }
          });

          this.newDataUser = this.requestData
          this.userChargeLoader = false
        }
        else {
          this.userChargeLoader = true
          this.requestData.forEach((element) => {
            if (element.trajet >= 1) {
              this.newDataUser.push(element);
            }
          });
          this.requestData = []
          this.userChargeLoader = false
        }



      } else {
        if (this.newDataUser.length != 0) {
          this.requestData = [];
          this.actif(this.startValue, this.endValue);
          this.userChargeLoader = false;
        } else {
          console.log("non non non");

          this.init();
          this.userChargeLoader = false;
        }
      }
    },
  },
};

/* const dots = document.querySelector('.loaading__dots')
  const addAnimate = ()=>{
    dots.classList.add('animate')
    setTimeout(()=>{
      dots.classList.remove('aniamte');
      setTimeout(()=>{
        addAnimate()
      },100)
    }, 2600)


    }
    addAnimate(); */
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row rowBase">
      <div class="col-lg-12">
        <div class="card homeContainer">
          <div class="containerDot" v-if="requestData.length == 0 && newDataUser.length == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>

          <div class="card-body pt-0">
            <div>

              <button class="btn btn-success ml-1 mb-4 mt-2" style="background-color: #3aaa35;"
                @click="showLineModal = true"> Ajouter une ligne <i class="fa fa-plus"></i> </button>

            </div>
            <transition mode="out-in">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <li>
                    <span class="interval"> {{ startValue }}</span> -
                    <span class="interval">{{ endValue }}</span> sur
                    {{ totalValue }}
                  </li>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <li>
                    <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                      data-placement="bottom" title="Prem">
                      <ion-icon name="play-skip-back-outline"></ion-icon>
                    </button>
                    <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                      data-toggle="tooltip" data-placement="bottom" title="Préc">
                      <ion-icon name="chevron-back-outline"></ion-icon>
                    </button>
                    <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                      title="Suiv">
                      <ion-icon name="chevron-forward-outline"></ion-icon>
                    </button>
                    <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip" data-placement="bottom"
                      title="Dern">
                      <ion-icon name="play-skip-forward-outline"></ion-icon>
                    </button>
                  </li>
                </ul>
              </div>
            </transition>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Lignes Actives
                  </a>
                </template>
                <div class="row d-flex align-items-center mt-4 mb-3">
                  <!-- <div class="col-md-3 text-left">
                    <span class="superuser mb-1"></span>&nbsp; Superuser
                  </div>
                  <div class="col-md-3">
                    <div class="center">
                      <span class="mode">Tout</span>&nbsp;&nbsp;
                      <input type="checkbox" v-model="activeConducteur" :checked="activeConducteur" />&nbsp;&nbsp;
                      <span class="mode">Conducteur</span>
                    </div>
                  </div> -->
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div class="inputCol">
                      <!--   <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-md ml-2"
                        ></b-form-input>
                      </label> -->

                      <div class="input mb-2">
                        <input type="search" id="serchInput" placeholder="Entrez un mot clé..." v-model="recherche"
                          @keyup.enter="userSearch" />
                      </div>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive mt-2">
                  <b-table id="trajet" style="cursor: pointer" class="table-centered"
                    :items="requestData.length != 0 ? requestData : requestData" :fields="fields" responsive="sm"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered" @row-clicked="goToDetail"
                    :tbody-tr-class="rowClass">

                    <template v-slot:cell(username)="row">

                      <div>

                        {{ row.value }}
                        <!-- <span class="label label-warning">Taxi</span> -->
                        <!-- &nbsp;<span class="badge badge-pill badge-success m-2" style="font-size: 100%;background-color: goldenrod; color: #fff;" >Taxi</span> -->


                      </div>

                    </template>
                    <template v-slot:cell(badge)="row">
                      <div class="py-2 d-flex justify-content-center" :class="{
      'fa fa-star': `${row.value}` === 'false',
      'fa fa-star badge-soft-warning':
        `${row.value}` === 'true',
    }"></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div class="py-2 d-flex justify-content-center badge font-size-12" :class="{
      'badge-soft-danger': `${row.value}` === 'Suspendu',
      'badge-soft-success': `${row.value}` === 'Actif',
    }">
                        {{ row.value }}
                      </div>
                    </template>


                    <template v-slot:cell(passengerPhone)="row" style="text-align: center">
                      <a :href="'https://wa.me/' + ((row.value).replace(' ', '')).replace('+', '')" class="text-success"
                        v-b-tooltip.hover title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <!--   <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                    
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                          :aria-controls="trajet"
                        ></b-pagination>
                      </ul>
                    </div> -->
                    <transition mode="out-in">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <li>
                            <span class="interval"> {{ startValue }}</span> -
                            <span class="interval">{{ endValue }}</span> sur
                            {{ totalValue }}
                          </li>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <li>
                            <button class="myBouton" @click="firstVal()" :disabled="startValue == 1"
                              data-toggle="tooltip" data-placement="bottom" title="Prem">
                              <ion-icon name="play-skip-back-outline"></ion-icon>
                            </button>
                            <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                              data-toggle="tooltip" data-placement="bottom" title="Préc">
                              <ion-icon name="chevron-back-outline"></ion-icon>
                            </button>
                            <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                              title="Suiv">
                              <ion-icon name="chevron-forward-outline"></ion-icon>
                            </button>
                            <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip"
                              data-placement="bottom" title="Dern">
                              <ion-icon name="play-skip-forward-outline"></ion-icon>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                </div>
                <!--      <div class="row">
                  <h3 class="loading">
                    Loading   
                    <div class="loaading__dots animate" id="loaading__dots">
                      <span class="loading__dot"></span>
                      <span class="loading__dot"></span>
                      <span class="loading__dot"></span>
                      <span class="loading__dot"></span>
                     <span class="loading__dot-down"></span>
                    </div>
                  </h3>
                </div> -->
              </b-tab>
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active"> Lignes Inactives</a>
                </template>
                <div class="rowd-flex justify-content-between align-items-center mt-4">
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptionsD"></b-form-select>&nbsp;entrées
                      </label>
                    </div>
                  </div>

                  <!-- Search -->

                  <div class="inputCol p-3">
                    <div class="input">
                      <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filter" />
                    </div>
                  </div>

                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table id="trajetD" style="cursor: pointer" class="table-centered" :items="canceledRequestData"
                    :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered" @row-clicked="goToDetail">
                    <template v-slot:cell(badge)="row">
                      <div class="d-flex justify-content-center" :class="{
      'fa fa-star': `${row.value}` === 'false',
      'fa fa-star badge-soft-warning':
        `${row.value}` === 'true',
    }"></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div class="badge font-size-12" :class="{
      'badge-soft-danger': `${row.value}` === 'Suspendu',
      'badge-soft-success': `${row.value}` === 'Actif',
    }">
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <a href="javascript:void(0);" class="text-success" v-b-tooltip.hover
                        @click="activeUserPop(row.item)" title="Activer">
                        <i class="mdi mdi-check font-size-18"></i>
                      </a>
                      <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover @click="deleteUser(row.item)"
                        title="Supprimer" v-if="row.item.trajet == 0">
                        <i class="fas fa-trash"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPageD" :total-rows="rows" :per-page="perPageD"
                          :rows="rowsinactif"></b-pagination>
                      </ul>
                    </div>
                    <!--    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        pagination
                        <li>1-500 sur 7000</li>
                        <li>
                          <ion-icon name="chevron-back-outline"></ion-icon
                          >&nbsp;&nbsp;&nbsp;&nbsp;<ion-icon
                            name="chevron-forward-outline"
                          ></ion-icon>
                        </li>
                      </ul>
                    </div> -->
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal size='md' v-model="showLineModal" title="Nouvelle ligne" centered>
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="enjoyMusicInCar">Compagnie</label>
              <select class="form-control" id="enjoyMusicInCar" v-model="line.company">
                <option value="ALWAYS">Compagnie 1</option>
                <option value="NEVER">Compagnie 2</option>
                <option value="DEPEND">Compagnie ...</option>
              </select>
            </div>
            <div class="form-group">
              <label for="lastname">Départ de la ligne</label>
              <gmap-autocomplete :value="line.startPoint" type="text" class="form-control" id="depart"
                placeholder="Point de départ" @place_changed="setLineStartPoint" :select-first-on-enter="true"
                style="height: 50px">
              </gmap-autocomplete>
            </div>
            <div class="form-group">
              <label for="lastname">Arrivé de la ligne</label>
              <gmap-autocomplete :value="line.endPoint" type="text" class="form-control" id="depart"
                placeholder="Point d'arrivé" @place_changed="setLineEndPoint" :select-first-on-enter="true"
                style="height: 50px">
              </gmap-autocomplete>
            </div>
            <div class="form-group">
              <label for="escale">Escales</label>
              <gmap-autocomplete :value="line.escale" type="text" class="form-control" id="depart"
                placeholder="Points d'escales" @place_changed="setEscaleEndPoint" :select-first-on-enter="true"
                style="height: 50px">
              </gmap-autocomplete>
              <div>

              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="place">Place disponible</label>
              <input id="place" class="form-control" type="number" v-model="line.placeAvailable">
            </div>

            <div class="form-group">
              <label for="place">Pays</label>
              <input id="place" class="form-control" type="number" v-model="line.country">
            </div>

            <div class="form-group">
              <label for="smokeInCar">Heure Départ</label>
              <input class="form-control" type="text" v-model="line.startHour">
            </div>
            <div class="form-group">
              <label for="smokeInCar">Heure d'arrivée</label>
              <input class="form-control" type="text" v-model="line.endHour">
            </div>

          </div>

        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showLineModal = false">Fermer</b-button>
        <b-button variant="primary" @click="showLineModal = false">
          Ajouter
          <i class="fas fa-check ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showDelete" title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveUser">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="deleteModal" title="Voulez-vous vraiment réactiver cet utilisateur ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="deleteModal = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activeUser">Oui</b-button>
      </div>
    </b-modal>




    <!-- -->

  </Layout>
</template>

<style>
thead {
  background: #3AAA35;
}

thead div {
  color: #fff;
}

thead th:first-child {
  font-weight: 600 !important;
}

thead th span {
  color: #fff;
}

.nav-tabs .nav-link {
  background: #dc143c;
  transition: all .5s;
}

.nav-tabs .nav-link a {
  color: #fff;
}

.text-red {
  color: #dc143c;
}

.nav-tabs .nav-link a {
  color: #fff !important;
}

.text-green {
  color: #3AAA35;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff !important;
  font-weight: 600 !important;
  vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35 !important;
  border-bottom: 1px solid #000000 !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000 !important;
}

/*table filter design*/

.input {

  width: 300px;
  height: 35px;
  position: relative;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

span.superuser {
  display: inline-flex;
  width: 20px;
  height: 20px;
  background-color: #fddb91;
  vertical-align: middle;
  border: none;
  box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.95);
}

.dataTables_filter {
  padding-top: 0;
  margin-top: -10px;
}

.inputCol {
  position: relative;
  float: right;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}

.pagination ul {
  position: relative;
  display: flex;
  padding: 10px 20px;
  align-items: center;
}

.myIcon {
  margin-top: 2px;
}

.loading__dots {
  display: inline-flex;
  column-gap: 0.25rem;
}

.loading__dot {
  position: relative;
  width: 8px;
  height: 8px;
  background-color: hsl(0, 0%, 12%);
  border-radius: 50%;
}

.loading__dot:nth-child(1) {
  position: absolute;
  transform: scale(0);
}

.loading__dot:nth-child(4) {
  background: transparent;
}

.loading__dot-down {
  display: block;
  width: 8px;
  height: 8px;
  background-color: hsl(0, 0%, 12%);
  border-radius: 50%;
  transform: translate(0);
}

.animate .loading__dot:nth-child(1) {
  animation: scale-dot 0.8s 0.2s forwards;
}

.animate .loading__dot:nth-child(2),
.animate .loading__dot:nth-child(3) {
  animation: move-right 0.6s forwards;
}

.animate .loading__dot-down {
  animation: move-right-down 0.8s 0.2s forwards linear,
    move-down 2.8s 0.3s forwards ease-in;
}

@keyframes scale-dot {
  100% {
    transform: scale(1);
  }
}

@keyframes move-right {
  100% {
    transform: translateX (0.75rem);
  }
}

@keyframes move-right-down {
  50% {
    transform: translate(1.5rem, 0.25rem);
  }

  100% {
    transform: translate(2rem, 0.5rem);
  }
}

@keyframes move-down {
  100% {
    transform: translate(10rem, 80vh);
  }
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -55px;
}

.homeContainer {
  position: relative;

}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 2%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dots {
  position: relative;
  width: 100px;
  height: 50px;
  transform: scale(0.25, 0.25);
  margin-left: -10px;
}

.dot {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0, -40%);
  opacity: 0;
  border-radius: 50%;
  background-color: hsl(0, 0%, 12%);
}

.dot-1 {
  animation: animation-dots 1.5s infinite linear 1s;
}

.dot-2 {
  animation: animation-dots 1.5s infinite linear 0.5s;
}

.dot-3 {
  animation: animation-dots 1.5s infinite linear;
}

@keyframes animation-dots {
  0% {
    left: -100px;
    opacity: 0;
  }

  20% {
    left: 0px;
    opacity: 1;
  }

  80% {
    left: 80px;
    opacity: 1;
  }

  100% {
    opacity: 0;
    left: 150px;
  }
}

.rowBase {
  position: relative;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

/* .interval{
padding: 4px;
  height: 40px;
  min-width: 100px;
  width: 80px;
  text-align: center;
  color: #262626;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: inset 0 5px 10px rgba(0, 0, 0, .1), 0 2px 5px rgba(0, 0, 0, .5);
  margin: 5px;

} */

.containerDot {
  z-index: 100;
}

.pac-container {
  z-index: 10000 !important;
}

/** Conducteur option */

.center input[type="checkbox"] {
  position: relative;
  width: 45px;
  min-width: 45px;
  height: 20px;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  border-radius: 20px;
  transition: -5px;
  cursor: pointer;
}

.center input:checked[type="checkbox"] {
  background: linear-gradient(0deg, #00d22d, #717171);
}

.center input[type="checkbox"]:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 0;
  left: 0;
  background: #fff;
  transform: scale(1, 1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  color: #d6d2f8;
}

.center input:checked[type="checkbox"]:before {
  left: 25px;
}

.center {
  display: flex;
  align-items: center;
}

.mode {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #495057;
}


.multiselect__tags {
  min-height: 50px !important;
  height: 50px;
}
</style>
